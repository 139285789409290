@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn {
    @apply px-3 py-2 hover:opacity-75 active:opacity-100 rounded-md cursor-pointer disabled:opacity-30 disabled:cursor-default font-normal;
  }
  .btn-primary {
    @apply bg-gold text-black;
  }
  .icon {
    @apply w-5 h-5
  }
}
