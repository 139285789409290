.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* PAGE HEADER SECTION START */

#pageHeader {
  font-family: Figtree, sans-serif;
  font-style: normal;
  font-weight: 400;
}

#pageHeader .container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

#pageHeader .header {
  left: 0;
  margin: 0 auto;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 999;
}

#pageHeader .header__skip {
  height: 1px;
  left: -1000px;
  overflow: hidden;
  position: absolute;
  text-align: left;
  top: -1000px;
  width: 1px;
}

#pageHeader a {
  color: #0270e0;
  text-decoration: none;
  cursor: pointer;
}

#pageHeader .header__container.content-wrapper {
  border-radius: 0;
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  padding: 21px 39px;
}

#pageHeader .content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

#pageHeader .header .header__logo span.logo-text {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  padding-left: 10px;
  pointer-events: none;
  user-select: none;
}

#pageHeader .nav-wrapper {
  align-items: center;
  display: flex;
}

#pageHeader .nav-wrapper .hs-menu-wrapper {
  margin-right: 19px;
}

#pageHeader .hs-menu-wrapper ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding-left: 0;
}

#pageHeader .hs-menu-wrapper ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding-left: 0;
}

#pageHeader .nav-wrapper .hs-menu-wrapper > ul li {
  margin-left: 39px;
}

#pageHeader .nav-wrapper .hs-menu-wrapper > ul li a,
.nav-wrapper .hs-menu-wrapper > ul ul li a {
  color: #fff;
}

#pageHeader .nav-wrapper .hs-menu-wrapper > ul li a {
  color: #fff;
  font-size: 18px;
  font-weight: 800;
  line-height: 138%;
  text-transform: capitalize;
}

/* #pageHeader .nav-wrapper .hs-menu-wrapper > ul li.hs-menu-depth-1 a {
  color: #000;
} */

#pageHeader .login-buttons ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

#pageHeader .login-buttons li.primary-btn {
  position: relative;
}

#pageHeader .login-buttons .secondary-btn {
  margin-left: 10px;
}

#pageHeader .login-buttons .primary-btn a {
  background: #fff;
  border: 1px solid #000;
  border-radius: 10px;
}

#pageHeader .login-buttons a {
  color: #000;
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
  font-weight: 800;
  line-height: 138%;
  padding: 10.1px 15px;
  text-align: center;
  text-transform: capitalize;
}

#pageHeader .cs-button,
a[role="button"] {
  transition: all 0.3s;
}

#pageHeader .login-buttons .secondary-btn a {
  border-radius: 10px;
  font-weight: 800;
}

#pageHeader #hs_cos_wrapper_module_16937522322317 .notification-bar-wrapper {
  background-color: rgba(211, 166, 81, 1);
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 18px;
}

#pageHeader
  #hs_cos_wrapper_module_16937522322317
  .notification-bar-wrapper
  .text
  > * {
  color: rgba(255, 255, 255, 1);
  margin-bottom: 0 !important;
}

#pageHeader
  #hs_cos_wrapper_module_16937522322317
  .notification-bar-wrapper
  .top-button
  a {
  background-color: rgba(255, 255, 255, 1);
  color: rgba(0, 0, 0, 1);
}

#pageHeader
  #hs_cos_wrapper_module_16937522322317
  .notification-bar-wrapper
  .text {
  text-align: center;
}

/* PAGE HEADER SECTION END */

/* PAGE FOOTER SECTION START */
:root {
  --column-gap: 2.13%;
  --column-width-multiplier: 8.333;
}

#pageFooter footer.footer {
  background: #000;
  color: #fff;
  font-size: 16px;
  line-height: 130%;
  text-transform: capitalize;
}

#pageFooter .footer .content-wrapper {
  padding-bottom: 81px;
  padding-top: 55px;
}

#pageFooter .content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

#pageFooter .row-fluid {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

#pageFooter .row-fluid .span1,
.row-fluid .span10,
.row-fluid .span11,
.row-fluid .span12,
.row-fluid .span2,
.row-fluid .span3,
.row-fluid .span4,
.row-fluid .span5,
.row-fluid .span6,
.row-fluid .span7,
.row-fluid .span8,
.row-fluid .span9 {
  min-height: 1px;
  width: 100%;
}

#pageFooter .footer .logo {
  margin-bottom: 31px;
}

#pageFooter footer.footer .content {
  max-width: 268px;
  text-align: left;
}

#pageFooter .social-links__link svg {
  width: 13px;
  fill: #ccc;
  height: 13px;
}

#pageFooter p {
  margin: 0 0 1.2rem;
}

#pageFooter .social-links {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

#pageFooter .social-links__link {
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 100%;
  display: flex;
  height: 33.6px;
  justify-content: center;
  margin: 0 10px;
  width: 33.6px;
  border-color: #ccc;
}

#pageFooter .row-fluid .span8 {
  width: calc(
    var(--column-width-multiplier) * 1% * 8 - var(--column-gap) *
      var(--column-width-multiplier) * 4 / 100
  );
}

#pageFooter .footer-menu .hs-menu-wrapper ul {
  justify-content: space-around;
}

#pageFooter .hs-menu-wrapper ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding-left: 0;
  text-align: left;
}

#pageFooter .footer-menu .hs-menu-wrapper > ul > li > a {
  color: #fff;
  display: block;
  font-size: 24px;
  font-weight: 800;
  line-height: 130%;
  margin-bottom: 30px;
  text-transform: capitalize;
}

#pageFooter .hs-menu-wrapper.hs-menu-flow-horizontal .hs-menu-children-wrapper {
  flex-direction: column;
}

#pageFooter .footer-menu .hs-menu-wrapper ul {
  justify-content: space-around;
}

#pageFooter .footer-menu .hs-menu-wrapper > ul > li ul li a {
  color: #fff;
  display: block;
  font-size: 16px;
  font-weight: 800;
  line-height: 237.5%;
  text-transform: capitalize;
}
/* PAGE FOOTER SECTION END */

@media (min-width: 1025px) {
  #pageHeader .mobile-trigger {
    display: none;
  }
  #pageHeader .nav-wrapper {
    display: flex !important;
  }
  /* #pageHeader .nav-wrapper .hs-menu-wrapper {
    margin: 0 0 11px;
  } */
  #pageHeader .nav-wrapper .hs-menu-wrapper > ul {
    justify-content: flex-end;
  }
}

@media (min-width: 768px) {
  #pageFooter .row-fluid {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  #pageFooter .row-fluid .span4 {
    width: calc(
      var(--column-width-multiplier) * 1% * 4 - var(--column-gap) *
        var(--column-width-multiplier) * 8 / 100
    );
  }
}

@media (max-width: 1024px) {
  #pageHeader .header {
    box-shadow: 0 4px 8px 0 rgb(0 0 0/4%);
    top: 0;
    width: 100%;
  }

  #pageHeader .mobile-trigger {
    cursor: pointer;
    height: 20px;
  }

  #pageHeader .nav-wrapper {
    background: #000;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: none;
    height: auto;
    max-width: 283px;
    padding: 8px 21px 20px;
    position: absolute;
    right: 0;
    top: 100%;
    width: 100%;
  }

  #hs_cos_wrapper_site_logo img{
    height: auto;
    width: 162px;
  }

  #hs_cos_wrapper_site_logo img{
    height: auto;
    width: 162px;
  }

  #pageHeader .nav-wrapper .hs-menu-wrapper {
    margin: 0 0 11px;
  }

  #pageHeader .nav-wrapper .hs-menu-wrapper ul {
    display: block;
  }

  #pageHeader .nav-wrapper .hs-menu-wrapper > ul li {
    margin: 0;
  }

  #pageHeader .nav-wrapper .hs-menu-wrapper > ul li a {
    border-bottom: 1px solid rgba(0, 0, 0, 0.6);
    display: block;
    padding: 10px 0;
    text-align: left;
  }

  #pageHeader .login-buttons a,
  .login-buttons ul {
    display: block;
  }

  #pageHeader .login-buttons a {
    font-size: 16px;
    line-height: 22.08px;
  }

  #pageHeader .login-buttons .secondary-btn {
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  #pageHeader .header .header__logo span.logo-text {
    font-size: 12px;
    font-weight: 500;
    margin-top: 10px;
    padding-left: 0;
  }
  
  #hs_cos_wrapper_site_logo img{
    height: auto;
    width: 116px;
  }

  #pageHeader .hs-menu-wrapper.hs-menu-flow-horizontal ul {
    flex-direction: column;
  }

  #pageHeader .header__container.content-wrapper {
    padding: 16px 15px 15px;
  }

  #pageHeader .nav-wrapper .hs-menu-wrapper > ul li a {
    font-size: 14px;
  }

  #pageHeader .menu-open #menuopen {
    display: none;
  }

  #pageHeader #menuclose {
    display: none;
  }

  #pageHeader .menu-open #menuclose {
    display: inline-block;
  }

  #pageHeader .header__container.content-wrapper {
    align-items: center;
    padding: 21px 15px;
  }

  #pageHeader .login-buttons ul {
    display: block;
  }

  #pageHeader .login-buttons ul li {
    margin: 0 0 10px;
  }

  #pageFooter .footer .content-wrapper {
    padding-bottom: 55px;
    padding-top: 55px;
  }

  #pageFooter footer.footer .logo img {
    max-width: 211px;
  }

  #pageFooter footer.footer .content {
    max-width: 100%;
  }

  #pageFooter .hs-menu-wrapper.hs-menu-flow-horizontal ul {
    flex-direction: column;
  }

  #pageFooter .footer-menu .hs-menu-wrapper > ul > li > a {
    margin-top: 27px;
  }

  #pageFooter .footer-menu .hs-menu-wrapper > ul > li:not(:last-child) {
    margin-bottom: 0;
  }

  #pageFooter .footer-menu .hs-menu-wrapper > ul > li {
    border-top: 1px solid #fff;
    margin-top: 30px;
  }
}
